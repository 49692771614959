
import { Component, Vue } from "vue-property-decorator";
import { appName } from "@/env";
import { dispatchPasswordRecovery } from "@/store/main/actions";

@Component
export default class Login extends Vue {
  public valid = true;
  public username: string = "";
  public appName = appName;

  public cancel() {
    this.$router.back();
  }

  public submit() {
    dispatchPasswordRecovery(this.$store, { username: this.username });
  }
}
